import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { WidgetComponents, WidgetElements } from '../../../../utils/bi/consts';
import { SetSelectedTime } from '../setSelectedTime/setSelectedTime';
import { FlowElements } from '../../Hooks/useFlow';
import { SetFocusedElement } from '../setFocusedElement/setFocusedElement';
import { QueryAvailabilityResponse } from '@wix/ambassador-availability-calendar/types';
import { Optional, TriggeredByOptions } from '../../../../types/types';
import { CalendarBiLogger } from '../../../../utils/bi/biLoggerFactory';
import {
  bookingsCalendarClick,
  bookingsCalendarBookingDetailsLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { isSelectedTimeSlotWithWaitingListIndication } from '../../../../utils/timeSlots/timeSlots';

export type OnTimeSelected = (selectedTime: string) => void;

export function createOnTimeSelectedAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedTimeAction: SetSelectedTime,
  setFocusedElement: SetFocusedElement,
): OnTimeSelected {
  return async (selectedTime: string) => {
    const [state] = getControllerState();
    const { biLogger, experiments } = context;
    const { availableSlots } = state;

    const isAutoSelectTimeEnabled = experiments.enabled(
      'specs.bookings.calendarAutoSelectTime',
    );

    setSelectedTimeAction(selectedTime, TriggeredByOptions.TIME_SELECTED);

    sendBiEvents(
      availableSlots,
      selectedTime,
      biLogger,
      isAutoSelectTimeEnabled,
    );

    setFocusedElement(FlowElements.SIDEBAR);
  };
}

const sendBiEvents = (
  availableSlots: Optional<QueryAvailabilityResponse>,
  selectedTime: string,
  biLogger: CalendarBiLogger,
  isAutoSelectTimeEnabled = false,
) => {
  const isWaitingListFlow = isSelectedTimeSlotWithWaitingListIndication(
    availableSlots,
    selectedTime,
  );
  const waitListProperty = isWaitingListFlow ? { waitlist: true } : {};

  void biLogger.report(
    bookingsCalendarClick({
      element: WidgetElements.TIME_SLOT,
      component: WidgetComponents.TIME_PICKER,
      properties: JSON.stringify({
        selectedTime,
        ...waitListProperty,
      }),
    }),
  );

  if (!isAutoSelectTimeEnabled) {
    void biLogger.report(
      bookingsCalendarBookingDetailsLoad({
        triggeredBy: TriggeredByOptions.TIME_SELECTED,
        selectedSlot: selectedTime,
        properties: JSON.stringify({
          ...waitListProperty,
        }),
      }),
    );
  }
};
