import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import {
  isFullSlot,
  isSlotNoBookFlow,
  isSlotWithBookingsPolicyViolation,
  isSlotWithOpenWaitingList,
} from '../../../../utils/slotAvailability/slotAvailability';
import { isWaitingListFlow } from '../../../../utils/waitingList/waitingList';
import { getBookingPreferencesForSelectedTime } from '../../../../utils/bookingPreferences/bookingPreferencesForSelectedTime';
import { isLayoutWithTimeSlot } from '../../../../utils/layouts';
import { CalendarErrors, SlotsStatus } from '../../../../types/types';
import { Service } from '@wix/bookings-uou-types';
import { isPendingApprovalFlow } from '../../../../utils/serviceUtils/serviceUtils';
import { CalendarStatus } from '../widgetViewModel/widgetViewModel';

export type CtaViewModel = {
  label: string;
  disabled: boolean;
  fullWidth: boolean;
  loading: boolean;
};

export const memoizedCtaViewModel: MemoizedViewModalFactory<CtaViewModel> = {
  dependencies: {
    settings: [
      'calendarLayout',
      'buttonsFullWidth',
      'rescheduleButton',
      'buttonsFullWidth',
      'nextButton',
      'joinWaitlistButton',
      'pendingApprovalButton',
    ],
    state: [
      'selectedBookingPreferences',
      'calendarErrors',
      'availableServices',
      'rescheduleBookingDetails',
      'slotsStatus',
      'calendarStatus',
      'selectableSlotsAtSelectedTime',
    ],
  },
};

export function createCtaViewModel({
  state,
  context,
  slots,
}: ViewModelFactoryParams<CalendarState, CalendarContext> & {
  slots: SlotAvailability[];
}): CtaViewModel {
  const { settings, settingsParams } = context;

  const label = getLabel({ context, state, slots });
  const disabled = isDisabled({ context, state, slots });
  const fullWidth = settings.get(settingsParams.buttonsFullWidth);
  const loading = isLoading({ context, slots, state });

  return {
    label,
    disabled,
    fullWidth,
    loading,
  };
}

const isReschedulingFlow = (state: CalendarState) =>
  !!state.rescheduleBookingDetails;

const getLabel = ({
  context,
  state,
  slots,
}: {
  state: CalendarState;
  context: CalendarContext;
  slots: SlotAvailability[];
}): string => {
  const { getContent, settingsParams, settings, t } = context;
  const { availableServices, selectedBookingPreferences, calendarErrors } =
    state;

  let service: Service;

  const isLayoutWithTimeSlots = isLayoutWithTimeSlot(settings, settingsParams);
  const isRescheduling = isReschedulingFlow(state);
  let isNoBookFlow = false;
  let isWaitingList;

  if (isLayoutWithTimeSlots) {
    service = availableServices[0];
    const bookingPreferences = getBookingPreferencesForSelectedTime({
      selectableSlotsAtSelectedTime: slots,
      selectedBookingPreferences,
      calendarErrors,
      context,
      state,
    });

    isWaitingList = isWaitingListFlow({
      selectableSlots: slots,
      selectedBookingPreferences,
      bookingPreferences,
    });
  } else {
    const slot = slots[0];
    service = availableServices.find(
      (availableService) => availableService.id === slot.slot?.serviceId,
    )!;
    isNoBookFlow = isSlotNoBookFlow(slot);
    isWaitingList = isSlotWithOpenWaitingList(slot);
  }
  const isPendingApproval = isPendingApprovalFlow(service);

  if (isNoBookFlow) {
    return t('app.settings.defaults.button.more-info');
  }

  if (isRescheduling) {
    return getContent({
      settingsParam: settingsParams.rescheduleButton,
      translationKey: 'app.rescheduled-booking.booking-details.cta',
    });
  }

  if (isPendingApproval) {
    return getContent({
      settingsParam: settingsParams.pendingApprovalButton,
      translationKey:
        'app.settings.defaults.booking-details.pending-approval.text',
    });
  }

  if (isWaitingList) {
    return getContent({
      settingsParam: settingsParams.joinWaitlistButton,
      translationKey:
        'app.settings.defaults.booking-details.join-waitlist.text',
    });
  }

  return getContent({
    settingsParam: settingsParams.nextButton,
    translationKey: isLayoutWithTimeSlots
      ? 'app.settings.defaults.booking-details.book-now.text'
      : 'app.settings.defaults.button.book',
  });
};

const isDisabled = ({
  context,
  state,
  slots,
}: {
  state: CalendarState;
  context: CalendarContext;
  slots: SlotAvailability[];
}): boolean => {
  const { settings, settingsParams } = context;
  const isLayoutWithTimeSlots = isLayoutWithTimeSlot(settings, settingsParams);

  if (isLayoutWithTimeSlots) {
    const { calendarErrors, slotsStatus } = state;

    const disableCTAWhenNoAvailabilityOnSelectedDate =
      slotsStatus === SlotsStatus.NO_AVAILABLE_SLOTS;

    return (
      disableCTAWhenNoAvailabilityOnSelectedDate ||
      calendarErrors.some(
        (error) =>
          error === CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING ||
          error ===
            CalendarErrors.NO_VALID_PRICING_PLAN_IN_RESCHEDULE_FLOW_ERROR,
      )
    );
  } else {
    const slot = slots[0];
    const isSlotBookableOnline = !isSlotNoBookFlow(slot);
    const isSlotWithPolicyViolation = isSlotWithBookingsPolicyViolation(slot);
    const isSlotFull = isFullSlot(slot) && !isSlotWithOpenWaitingList(slot);

    return isSlotBookableOnline && (isSlotWithPolicyViolation || isSlotFull);
  }
};

const isLoading = ({
  context,
  state,
  slots,
}: {
  state: CalendarState;
  context: CalendarContext;
  slots: SlotAvailability[];
}): boolean => {
  const { settings, settingsParams, experiments } = context;
  const { calendarStatus, selectableSlotsAtSelectedTime } = state;
  const isLayoutWithTimeSlots = isLayoutWithTimeSlot(settings, settingsParams);
  const isNavigationLoaderEnabled = experiments.enabled(
    'specs.bookings.calendar.navigationLoader',
  );

  if (!isNavigationLoaderEnabled) {
    return false;
  }

  if (isLayoutWithTimeSlots) {
    return calendarStatus === CalendarStatus.NAVIGATING;
  }

  const slot = slots[0];
  const selectedSlot = selectableSlotsAtSelectedTime?.[0];

  return (
    calendarStatus === CalendarStatus.NAVIGATING &&
    slot.slot?.sessionId === selectedSlot?.slot?.sessionId
  );
};
